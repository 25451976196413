import { Icon } from "@components/Icons"
import { SearchContent } from "@components/searchModel/SearchContent"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { Button, Col, Form, Input, Modal, Row } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import * as rf_Icon from "react-feather"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const SearchModel = ({
  visible,
  closeSearch,
  handleSearch,
  isEssay = false,
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [dataContent, setDataContent] = useState(null)
  const [totalResults, setTotalResults] = useState(0)
  const [loading, setLoading] = useState(false)

  const { feed, essay } = useStores()

  const [form] = Form.useForm()

  const { searchCreatorProfile } = feed

  const { searchEssay } = essay

  const ismobileS = useMedia({ maxWidth: 320 })

  useEffect(() => {
    sendQuery()
  }, [])

  useEffect(() => {
    form.setFieldsValue({ search: searchValue })
  }, [searchValue])

  const sendQuery = async (q = "") => {
    setLoading(true)

    const { data, total } = isEssay
      ? await searchEssay(q)
      : await searchCreatorProfile(q)

    setLoading(false)
    setDataContent(total > 0 ? data : [])
    setTotalResults(total)
  }

  const handleSubmit = async (values) => {
    const search = _get(values, "search", "")

    sendQuery(search)

    setSearchValue(search)
    setDataContent(null)
  }

  const handleClear = () => {
    setSearchValue("")
    handleSubmit({ search: "" })
  }
  const desktop = useMedia({ minWidth: 1024 })

  return (
    <Modal
      className="anonimatta-main-modal"
      centered
      visible={visible}
      footer={false}
      onCancel={handleSearch}
      closeIcon={<Icon name="CloseModal" />}
    >
      <Row>
        <Col span={24} className={styles.marginBottom}>
          <div className="flex">
            <Icon
              name="BackArrow"
              className={styles.iconBack}
              onClick={handleSearch}
            />

            <label
              className={cx(
                "f18 fw6 lh-copy mt2 mine-shaft",
                styles.modalTitle
              )}
            >
              {translate("searchTitle")}
            </label>
          </div>
        </Col>

        <Col span={24} className={styles.marginBottom}>
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={14}>
              <Col xs={17} sm={20} md={18}>
                <Form.Item name="search" style={{ margin: 0 }}>
                  <Input
                    className={styles.inputColor}
                    value={searchValue}
                    placeholder={ismobileS ? "" : "Digite o nome de um criador"}
                    /*  prefix={<rf_Icon.Search color="#777" size="20" />} */
                  />
                </Form.Item>
              </Col>
              <Col xs={7} sm={4} md={6}>
                <Button
                  type="primary"
                  className={styles.inputButton}
                  htmlType="submit"
                  //loading={loading}
                >
                  {desktop ? (
                    <> {translate("search")} </>
                  ) : (
                    <rf_Icon.Search color="#FFF" size="20" />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        {!loading && searchValue && totalResults > 0 ? (
          <Col span={24} className="mt0 mb24">
            <div style={{ paddingLeft: "32px" }}>
              <label className="f14 b lh-copy mine-shaft">{totalResults}</label>

              <label className="f14 normal lh-copy mine-shaft">
                {translate("nameResult")}
              </label>

              <label className="f14 normal lh-copy mine-shaft">
                {searchValue}
              </label>

              <Button type="link" onClick={handleClear}>
                {translate("toClean")}
              </Button>
            </div>
          </Col>
        ) : null}
        <span className={styles.line} />
        <Col span={24} className="containerProfiles">
          <Row
            gutter={{ md: 12, lg: 12, xl: 24, xxl: 24 }}
            className={cx({
              [styles.photoHeight]: totalResults > 12,
            })}
          >
            <Col span={24}>
              <SearchContent
                dataContent={dataContent}
                setSearchValue={setSearchValue}
                closeSearch={handleSearch}
                handleViewAllCreatorsProfile={sendQuery}
                sorryMessageContinue={
                  isEssay
                    ? translate("sorryMessageContinueEssay")
                    : translate("sorryMessageContinue")
                }
                setDataContent={setDataContent}
              />
            </Col>
          </Row>
        </Col>
        <span className={styles.line} />
      </Row>
    </Modal>
  )
}

export default SearchModel

import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import cx from "classnames"
import React from "react"

import styles from "../style.module.less"

const EmptyEssay = ({ isVideo, isPost, isEssay, isFeed, isFeedEmpty }) => {
  return (
    <div className={cx("flex justify-center w-100", styles.emptyState)}>
      <div className="tc justify-center items-center pt32 pb40">
        {isVideo && (
          <img
            className=""
            src={require("@images/icons/emptyVideo.svg")}
            alt="Este ensaio não tem vídeos"
          />
        )}
        {isPost && (
          <img
            className=""
            src={require("@images/icons/emptyPosts.svg")}
            alt="Este perfil ainda tem publicações"
          />
        )}
        {isEssay && (
          <img
            className=""
            src={require("@images/icons/emptyEssay.svg")}
            alt="Este perfil ainda não tem ensaios"
          />
        )}
        {isFeed && (
          <img
            className=""
            src={require("@images/icons/emptyFeed.svg")}
            alt="Você ainda não segue nenhum criador"
          />
        )}
        {isFeedEmpty && (
          <img
            className=""
            src={require("@images/icons/emptyFavorites.svg")}
            alt="Você ainda não segue nenhum criador"
          />
        )}
      </div>
    </div>
  )
}

export default EmptyEssay

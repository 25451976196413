import cx from "classnames"
import React from "react"

import CustomFilterbar from "@components/CustomFilterBarAnonimatta"
import SearchInput from "@components/SearchInput"

import { Category } from "../../modules/Essay/store"
import styles from "./styles.module.less"

const options = [
  {
    value: 0,
    label: "Todos",
  },
  {
    value: Category.NUDE,
    label: "Nude",
  },
  {
    value: Category.TOPLESS,
    label: "Topless",
  },
  {
    value: Category.LINGERIE,
    label: "Lingerie",
  },
]
const HeaderFilterBar = ({
  title,
  filters,
  handleChangeTab,
  handleLoading,
  handleSearch,
  className,
  isFeed,
  showSearch,
}) => {
  const searchBarWrapper =
    "w-100 flex justify-end flex-column flex-row-m mb0-m  mb24 mb0-l items-end-m items-end-l"
  const headerWrappperClassName = cx(
    "header__filter__container justify-between flex-m flex-l flex-row-l items-center-l",
    styles.header,
    {
      "items-center": !showSearch,
      "flex-column": showSearch,
    }
  )

  const titleClassName = cx(
    isFeed ? styles.header__title__feed : styles.header__title,
    { "mb24-ns mb24-m mb0-m mb0-l  mb8": showSearch }
  )

  return (
    <div className={headerWrappperClassName}>
      <h3 className={titleClassName}>{title}</h3>

      {showSearch && (
        <div className={searchBarWrapper}>
          <SearchInput
            options={options}
            handleChangeCategory={handleChangeTab}
            handleSearch={handleSearch}
            handleLoading={handleLoading}
          />
        </div>
      )}
      {!showSearch && (
        <CustomFilterbar
          filters={filters}
          handleChangeTab={handleChangeTab}
          handleSearch={handleSearch}
          className={className}
        />
      )}
    </div>
  )
}
export default HeaderFilterBar

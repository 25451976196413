import HeaderFilterBar from "@components/HeaderFilterBar"
import Loading from "@components/Loading"
import PageCenter from "@components/PageCenter"
import PostFeed from "@components/PostFeedAnonimatta"
import RecommendedProfiles from "@components/RecommendedProfilesAnonimatta"
import SearchModel from "@components/searchModel"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import { Col, Row } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useMedia } from "react-use-media"

import { getWindow } from "../../utils/getWindow"
import styles from "./style.module.less"

const FeedPage = ({
  refreshFeed,
  setRefreshFeed,
  isOpenSideBar,
  handleChangeSideBar,
}) => {
  const [filter, setFilter] = useState("all")
  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState()
  const [visibleModal, setVisibleModal] = useState(false)
  const [isClient, setIsClient] = useState(false)

  const [isBottom, setIsBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const [essayBanners, setEssayBanners] = useState(null)
  const [profileData, setProfileData] = useState(null)

  const { feed, banners } = useStores()
  const { getAllPosts, getMostFollowed } = feed
  const { getAllEssayBannersSite } = banners
  const filters = ["photos", "videos"]
  const paginationSize = 4
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: paginationSize,
  })

  const isDesktop = useMedia({
    minWidth: 1300,
  })

  const isMobile = useMedia({
    maxWidth: 767,
  })

  useEffect(() => {
    handleMostFollowed()
    handleGetEssayBanners()
    setIsClient(true)
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (isBottom && data && data.length) {
      setPagination((prevState) => ({
        current: prevState.current + paginationSize,
        pageSize: paginationSize,
      }))
    }
  }, [isBottom])

  useEffect(() => {
    if (data && data.length < totalData) {
      handleGetAllPosts()
    }
  }, [pagination])

  useEffect(() => {
    handleGetAllPosts()
  }, [filter])

  useEffect(() => {
    if (refreshFeed) {
      setData(null)
      handleGetAllPosts()
      setRefreshFeed(false)
    }
  }, [refreshFeed])

  const handleGetAllPosts = async () => {
    setLoading(true)

    const response = await getAllPosts(pagination, filter)
    const posts = _get(response, "data", [])
    const totalPosts = _get(response, "total", 0)

    setTotalData(totalPosts)
    setData([...data, ...posts])
    setLoading(false)
    setIsBottom(false)
  }

  const handleGetEssayBanners = async () => {
    const response = await getAllEssayBannersSite()

    setEssayBanners(response.data)
  }

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 500

    if (scrollTop + innerHeight >= scrollHeight) {
      setIsBottom(true)
    }
  }

  const handleChangeTab = (tab) => {
    if (tab === filter) {
      return
    }
    setPagination({
      current: 1,
      pageSize: 3,
    })
    setData(null)
    setFilter(tab)
  }

  const handleSearch = () => {
    setVisibleModal(!visibleModal)
  }

  const handleMostFollowed = async () => {
    const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))
    const idProfileLoged = _get(user, "id", "")
    const response = await getMostFollowed(idProfileLoged)

    const newResponse = response.mostFollowed.map((item) => ({
      id: item.id,
      urlProfile: item.urlProfile,
      name: item.name,
      username: item.username,
      location: `${item.city}, ${item.state}`,
      followers: item.followersCnt,
      affiliatedNickname: item.affiliatedNickname,
    }))
    setProfileData(newResponse)
    setRefreshFeed(true)
  }

  if (!isClient) return null

  const renderPostsColumn = () => (
    <>
      <HeaderFilterBar
        filters={filters}
        handleChangeTab={handleChangeTab}
        handleSearch={handleSearch}
        className={"custom-filter-tab-feed"}
        title={"Feed"}
        isFeed
      />

      <PostFeed
        setRefreshFeed={setRefreshFeed}
        data={data}
        className="post-feed-container"
        styles={{ backgroundColor: "blue" }}
        isFeed={true}
      />

      {loading ? <Loading /> : null}
    </>
  )

  return (
    <TranslateWrapper>
      <Helmet>
        <title>Feed - Anonimatta</title>
      </Helmet>

      <div className="feed">
        <PageCenter>
          {isDesktop ? (
            <div className="flex justify-center">
              <div
                className={cx("feed-content-desktop", styles.feed__container)}
              >
                <div className={styles.feed__posts}>{renderPostsColumn()}</div>
                <div className={styles.feed__recommended}>
                  <RecommendedProfiles
                    profileData={profileData}
                    handleMostFollowed={handleMostFollowed}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Row justify="space-between">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                order={isMobile ? 2 : 1}
                className={cx("feed_content_mobile")}
              >
                {renderPostsColumn()}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={16}
                order={isMobile ? 1 : 2}
              >
                <RecommendedProfiles
                  profileData={profileData}
                  handleMostFollowed={handleMostFollowed}
                />
              </Col>
            </Row>
          )}

          {visibleModal && (
            <SearchModel visible={visibleModal} handleSearch={handleSearch} />
          )}
        </PageCenter>
      </div>
    </TranslateWrapper>
  )
}

export default FeedPage

import { LoadingOutlined } from "@ant-design/icons"
import { Icon } from "@components/Icons/index"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { Avatar, Col, Row } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import _map from "lodash/map"
import React from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

export const SearchContent = ({
  urlProfile,
  dataContent,
  setSearchValue,
  handleViewAllCreatorsProfile,
  sorryMessageContinue,
  setDataContent,
  closeSearch,
  hideLoading,
  isLoading,
  customIcon,
  hideSeeAll,
  notFoundText,
  notFoundClassName,
}) => {
  const { authSite } = useStores()

  const { user } = authSite

  const ismobileS = useMedia({ maxWidth: 320 })

  const gotoProfile = (username) => {
    closeSearch()
    location.href = `/perfil/${username}`
  }

  if (!dataContent && !hideLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <img
          style={{ maxWidth: "40px" }}
          src={require(`@images/loading.gif`)}
        />
      </div>
    )
  }

  if (!dataContent.length && !isLoading) {
    return (
      <Row>
        <Col span={24}>
          <div className="flex justify-center">
            <div
              className={cx("flex flex-column items-center", styles.notResult)}
            >
              <Icon name={customIcon || "NotResult"} />

              <div className={cx("mt16", styles.topSorry, notFoundClassName)}>
                <label className="f14 b tc bold lh-copy mine-shaft">
                  {notFoundText || translate("sorryMessage")}
                </label>

                {!hideSeeAll && (
                  <label className="f14 tc normal lh-copy mine-shaft">
                    {sorryMessageContinue}
                  </label>
                )}
              </div>
            </div>
          </div>
        </Col>

        {!hideSeeAll && (
          <Col span={24}>
            <div className="tc mt12">
              <label
                className="f14 fw5 pointer tc lh-copy bb french-rose"
                onClick={() => {
                  setSearchValue("")
                  setDataContent(null)
                  handleViewAllCreatorsProfile()
                }}
              >
                {translate("seeAll")}
              </label>
            </div>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <Row gutter={[{ xs: 10, md: 12, xl: 54 }]} gap>
      {_map(dataContent, (item, index) => {
        const avatarSrc = `${process.env.S3_IMAGES}/${item.urlProfile}`
        return (
          <Col
            xs={ismobileS ? 12 : 8}
            sm={8}
            md={5}
            lg={4}
            xl={4}
            xxl={4}
            className="mt24 mb24 tc custom_column"
            key={index}
          >
            <a onClick={() => gotoProfile(item.username)}>
              <Avatar
                src={avatarSrc}
                className={cx("pointer db", styles.sizeAvatar)}
              />
              <label
                className={cx(
                  "f12 fw5 tc lh-copy flex items-center justify-center",
                  styles.nameModels
                )}
              >
                <p className="mb0">
                  {!item.nickname ? item.name : item.nickname}
                </p>
              </label>
            </a>
          </Col>
        )
      })}
    </Row>
  )
}
